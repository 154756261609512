#contact {
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    margin-top: 6rem;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    margin-top: 6rem;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    margin-top: 3rem;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    margin-top: 2rem;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    margin-top: 2rem;
  }
}

.Contact__Wrapper {
  display: flex;
  padding: 0 !important;
}

.Contact__form button:last-child {
  margin-top: 1rem;
}

.Contact__form {
  width: 100%;
  #reCaptcha {
    margin-top: 1rem !important;
  }
  div {
    margin: 0px !important;
  }
  .MuiInputBase-multiline {
    height: auto;
  }
}

.Contact__form--itemWrapper {
  margin: 0px !important;
  padding: 0px !important;
  p {
    font-weight: 600;
  }
}
